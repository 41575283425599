import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase';
import { Injectable } from '@angular/core';
@Injectable()
export class FireBaseService {
  db = firebase.firestore();
  collectionJSON = {};
  randomNumber(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  genericService(method, collection, query?: any, data?: any, columnsToSelect?: any): Observable<any> {
    return new Observable((observer) => {

      //Establish the connection
      var dto;
      if (this.collectionJSON[collection] !== undefined) {
        dto = this.collectionJSON[collection];
      }
      else {
        dto = this.db.collection(collection);
        this.collectionJSON[collection] = dto;
      }
      switch (method.toLowerCase()) {

        case "getbyid": {
          dto.doc(query).get().then((doc) => {

            let items = [];
            let data = doc.data();
            if (columnsToSelect !== null && columnsToSelect !== undefined && columnsToSelect.length > 0) {

              var obj = {
                key: doc.id
              }
              for (var i = 0; i < columnsToSelect.length; i++) {
                obj[columnsToSelect[i]] = data[columnsToSelect[i]]
              }
              items.push(obj);
            }
            else {
              var keys = Object.keys(data);
              var obj = {
                key: doc.id
              }
              for (var i = 0; i < keys.length; i++) {
                obj[keys[i]] = data[keys[i]]
              }
              items.push(obj);

            }

            observer.next(items);

          });
          break;
        }
        case "getrandom": {
          if (query !== null && query !== undefined && query.length > 0) {
            dto.where(query[0], query[1], query[2]).onSnapshot((querySnapshot) => {
              let items = [];
              var random=this.randomNumber(0,(querySnapshot.docs.length-2));
              var docs=querySnapshot.docs.slice();
              docs=docs.splice(random,3)
              docs.forEach(doc => {
                let data = doc.data();
                if (columnsToSelect !== null && columnsToSelect !== undefined && columnsToSelect.length > 0) {

                  var obj = {
                    key: doc.id
                  }
                  for (var i = 0; i < columnsToSelect.length; i++) {
                    obj[columnsToSelect[i]] = data[columnsToSelect[i]]
                  }
                  items.push(obj);
                }
                else {
                  var keys = Object.keys(data);
                  var obj = {
                    key: doc.id
                  }
                  for (var i = 0; i < keys.length; i++) {
                    obj[keys[i]] = data[keys[i]]
                  }
                  items.push(obj);

                }
              });
            

              observer.next(items);

            });
          }
          else {
            dto.onSnapshot((querySnapshot) => {
              let items = [];
              querySnapshot.forEach((doc) => {
                let data = doc.data();
                if (columnsToSelect !== null && columnsToSelect !== undefined && columnsToSelect.length > 0) {

                  var obj = {
                    key: doc.id
                  }
                  for (var i = 0; i < columnsToSelect.length; i++) {
                    obj[columnsToSelect[i]] = data[columnsToSelect[i]]
                  }
                  items.push(obj);
                }
                else {
                  var keys = Object.keys(data);
                  var obj = {
                    key: doc.id
                  }
                  for (var i = 0; i < keys.length; i++) {
                    obj[keys[i]] = data[keys[i]]
                  }
                  items.push(obj);

                }
              });
              observer.next(items);

            });
          }

          break;
        }
        case "get": {
          if (query !== null && query !== undefined && query.length > 0) {
            dto.where(query[0], query[1], query[2]).onSnapshot((querySnapshot) => {
              let items = [];
              querySnapshot.forEach((doc) => {
                let data = doc.data();
                if (columnsToSelect !== null && columnsToSelect !== undefined && columnsToSelect.length > 0) {

                  var obj = {
                    key: doc.id
                  }
                  for (var i = 0; i < columnsToSelect.length; i++) {
                    obj[columnsToSelect[i]] = data[columnsToSelect[i]]
                  }
                  items.push(obj);
                }
                else {
                  var keys = Object.keys(data);
                  var obj = {
                    key: doc.id
                  }
                  for (var i = 0; i < keys.length; i++) {
                    obj[keys[i]] = data[keys[i]]
                  }
                  items.push(obj);

                }
              });

              observer.next(items);

            });
          }
          else {
            dto.onSnapshot((querySnapshot) => {
              let items = [];
              querySnapshot.forEach((doc) => {
                let data = doc.data();
                if (columnsToSelect !== null && columnsToSelect !== undefined && columnsToSelect.length > 0) {

                  var obj = {
                    key: doc.id
                  }
                  for (var i = 0; i < columnsToSelect.length; i++) {
                    obj[columnsToSelect[i]] = data[columnsToSelect[i]]
                  }
                  items.push(obj);
                }
                else {
                  var keys = Object.keys(data);
                  var obj = {
                    key: doc.id
                  }
                  for (var i = 0; i < keys.length; i++) {
                    obj[keys[i]] = data[keys[i]]
                  }
                  items.push(obj);

                }
              });
              observer.next(items);

            });
          }

          break;
        }
        case "post": {

          dto.add(data).then((doc) => {
            observer.next({
              key: doc.id,
            });
          });
          break;
        }
        case "put": {
          dto.doc(data.key).set(data).then(() => {
            observer.next({
              key: data.id,
            });

          });

          break;
        }
        case "delete": {
          dto.doc(data.key).delete().then(() => {
            observer.next({
              status: true,
            });

          })
          break;
        }

      }


    });
  }


  // Product Related Service Calls to Fire Base -- END


  checkFileType(fileExtension): boolean {
    var isAccepted = true;
    switch (fileExtension) {
      case "js":
      case "doc":
      case "docx":

      case "ppt":
      case "pptx":

      case "xls":
      case "xlsx":

      case "pdf":
      case "json":
      case "ts":
      case "html":
      case "sql":
      case "dll":
      case "java":
      case "jnl":
      case "jar":
      case "ps1":
      case "pyc":
      case "zip":
      case "rar":
      case "exe":

      case "py": {
        isAccepted = false;
        break;
      }
      default: {
        isAccepted = true;
        break;
      }
    }
    return isAccepted;

  }
}