import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import {
  MatInputModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatTableModule,
  MatIconModule,
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatSnackBarModule
} from "@angular/material";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app.routing';
import { FireBaseService } from './services/fire-base.service';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SidemenuComponent } from './shared/sidemenu/sidemenu.component';
import { ToolbarComponent } from './shared/toolbar/toolbar.component';
import { SharedModule } from './shared/shared-module';
import { CustomerService } from './services/customer.service';
// import { PreloaderComponent } from './shared/preloader/preloader.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { JwtModule } from '@auth0/angular-jwt';
import { ShareButtonsModule } from '@ngx-share/buttons';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AccessDeniedComponent } from './shared/accessdenied/accessdenied.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    SidemenuComponent,
    ToolbarComponent,
    AccessDeniedComponent
    
    
    // PreloaderComponent,

  ],
  imports: [
    BrowserModule, AppRoutingModule, BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatSnackBarModule,MatPasswordStrengthModule.forRoot(),
    SharedModule,
    JwtModule.forRoot({
      config: {
        throwNoTokenError: false,
      }

    }),ShareButtonsModule.withConfig({
      debug: true
    }), ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [FireBaseService,CustomerService],
  bootstrap: [AppComponent],
  entryComponents:[]
})
export class AppModule { }
