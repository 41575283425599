import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { TermsandconditionsComponent } from '../termsandconditions/termsandconditions.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  TCDialogRef: MatDialogRef<TermsandconditionsComponent, any>;

  constructor( public dialog: MatDialog) { }
  showVersion=environment.production;
  IsSiteMaintenance=environment.IsSiteMaintenance;
  ngOnInit() {
  }
  OpenTC(){
    this.TCDialogRef = this.dialog.open(TermsandconditionsComponent, {
      position: {
        top: '60px'
      },
      // height:'75%'
    });
  }

}
