import { Component, PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import * as firebase from 'firebase';
import { Observable } from 'rxjs';
import { CustomerService } from './services/customer.service';
import { FireBaseService } from './services/fire-base.service';
import { environment } from 'src/environments/environment';
import { SwUpdate } from '@angular/service-worker';
import { NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Vegan Chemistry';
  user: Observable<any>;
  userName = '';
  email = '';
  photoUrl = '';
  emailVerified = false;
  IsAdmin = false;
  showLoader = true;

  showVersion=environment.production;
  IsSiteMaintenance=environment.IsSiteMaintenance;
  openPage(pageUrl){
    window.open(pageUrl,"_blank");
  }
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
    firebase.auth().onAuthStateChanged((user) => {

      if (user != null) {

        this.cusServ.setUserDetails(user);

      }
      else {
        this.cusServ.setUserDetails(null);
        localStorage.removeItem('token')
      }

    });
    this.showLoader = false;
  }
  // ,private fs: FireBaseService
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
  @Inject(APP_ID) private appId: string,public cusServ: CustomerService, private swUpdate: SwUpdate,private router: Router) {
    firebase.initializeApp(environment.firebaseConfig);
    this.swUpdate.available.subscribe(event => {
      this.updateToLatest();
    });
  }
  isMobile() {
    return window.innerWidth <= 640;
}
  updateToLatest(): void {
    this.swUpdate.activateUpdate().then(() => document.location.reload());
  }
  onActivate(e) {
    if (isPlatformBrowser(this.platformId)) {
      let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
          window.scrollTo(0, pos - 50); // how far to scroll on each step
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 16);
    }
    

    var bodyElms = document.getElementsByTagName("body");
    if (bodyElms.length > 0) {
      var obj = bodyElms[0] as HTMLElement;
      obj.style.backgroundColor = "#fff";
    }
  }


}
