import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar, MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-termsandconditions',
  templateUrl: './termsandconditions.component.html',
  styleUrls: ['./termsandconditions.component.scss']
})
export class TermsandconditionsComponent implements OnInit {

  // @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<TermsandconditionsComponent>
  constructor(private custService:CustomerService) {
    this.custService.showLoader();

  }
  ngOnInit() {
    
    var docs=document.getElementById('footer');
    docs.style.position='';
    window.scrollTo(0,0);
    this.custService.hideLoader();

  }
  isMobile() {
    return window.innerWidth <= 640;
}
  close() {
    // this.dialogRef.close();
  }
}
