import { Component, OnInit, Inject } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { FireBaseService } from '../../../services/fire-base.service';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { title } from 'process';
@Component({
  selector: 'app-customer-new-user',
  templateUrl: './new-user.component.html',
  styleUrls:['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {
  dataSource = [];
  user: Observable<any>;
  emailId: string;
  errorMessage: string;
  isEmailSent: boolean = false;
  bindModel: any;
  passwordStrength=0;
  invalidMailId=false;
  data:any;
  constructor(  private fs: FireBaseService, private objMatSnackBar: MatSnackBar, private title: Title, private router: Router, private cust: CustomerService) {
    this.title.setTitle("Vegan Chemistry - Sign Up");

  }
  validateDetails() {
    var ret = true;
    if (this.bindModel.UserName == '' || this.bindModel.Password == '') {
      ret = false;
      this.objMatSnackBar.open('Please enter a valid Email ID & Password!', '', { duration: 3000, });

    }

    return ret;
  }
  onStrengthChanged(event) {
    this.passwordStrength=event;
  }

  setUserDetails(user) {

    this.fs.genericService("GET", "users", ["userId", "==", user.uid]).subscribe(res => {
      if (res && res.length == 0) {
        var myObj = {}
        myObj["displayName"] = user["displayName"];
        myObj["email"] = user["email"];
        myObj["isAnonymous"] = user["isAnonymous"];
        myObj["emailVerified"] = user["emailVerified"];
        myObj["phoneNumber"] = user["phoneNumber"];
        myObj["photoURL"] = user["photoURL"];
        myObj["userId"] = user["uid"];
        myObj['lastLoggedInIP'] = "";
        myObj['availedCoupons'] = [];
        myObj['availableCoupons'] = [];
        this.fs.genericService("GET", "coupons", ["Status", "==", true]).subscribe((res: any) => {
          if (res && res.length > 0) {
            res.forEach(element => {
              myObj['availableCoupons'].push(element.couponCode);
            });
          }

          this.fs.genericService("POST", "users", null, myObj).subscribe(resp => {
            if (resp) {
              // this.bindModel = myObj;
            }
          });
        });
      }
    });
    if (!localStorage.getItem('token') || localStorage.getItem('token') == '')
      this.fs.genericService("GET", "userrole", ["usermailid", "==", user.email]).subscribe(res => {
        if (res !== undefined && res.length > 0) {
          localStorage.setItem('token', res[0]["role"]);
        }
        else {
          localStorage.removeItem('token');

        }

     
        this.router.navigateByUrl("");



      });
  }
  createAccount() {
    if (this.validateDetails()) {
      var provider = new firebase.auth.EmailAuthProvider();
      
      firebase.auth().createUserWithEmailAndPassword(this.bindModel.UserName, this.bindModel.Password)
        .then((result) => {
          this.setUserDetails(result.user);

          firebase.auth().currentUser.sendEmailVerification();

          
          var redirectPath = localStorage.getItem('RedirectToUrl');
          if (redirectPath && redirectPath != '') {
            localStorage.removeItem('RedirectToUrl');
            this.router.navigateByUrl(redirectPath);
          }
          else {
  
              this.router.navigateByUrl("/Products");
  
          }

        })
        .catch(error =>{

          if(error["code"]==="auth/email-already-in-use"){
            this.invalidMailId=true;
          }
        });
    }

  }

  isMobile() {
    return window.innerWidth <= 640;
  }
  ngOnInit() {
    this.cust.hideLoader();
    this.bindModel = {
      UserName: '',
      Password: '',
      ConfirmPassword: ''
    }
    if(this.isMobile())
    {
      
    var docs=document.getElementById('footer');
    docs.style.position='fixed';

  }

  }


}
