import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { FireBaseService } from 'src/app/services/fire-base.service';
import * as firebase from 'firebase';
import { NewUserComponent } from 'src/app/customer/login/new-user/new-user.component';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
// import {FormControl} from '@angular/forms';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  bindModel: any;
  categoryArr = [];
  IsAdmin = false;
  cartNumber = '';
  navbarOpen = false;
  settings = {};
  AdminMenuArr = [];
  geolocationPosition: any;
  IsSiteMaintenance = environment.IsSiteMaintenance;
  searchText = '';

  signIndialogRef2: MatDialogRef<NewUserComponent, any>;
  @Input('IsAdmin') set _IsAdmin(val) {
    this.IsAdmin = val;
  }
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  ipAddress = "";
  constructor(private http: HttpClient, private fs: FireBaseService, public custService: CustomerService, private router: Router, public dialog: MatDialog) {

    this.getIPAddress();
    this.loadSettings();

  }
  deferredPrompt: any;
  showButton = false;
  isProd = environment.production;
  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    console.log(e);
    e.preventDefault();
    this.deferredPrompt = e;
    this.showButton = true;
  }
  addToHomeScreen() {
    this.showButton = false;
    this.deferredPrompt.prompt();
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });
  }
  loadSettings() {
    this.fs.genericService("GET", "settings").subscribe(res => {
      if (res && res.length > 0) {
        this.settings = res[0];
        if (this.settings['ShowCovidInfo'] == true) {

        }
      }
    });

  }
  isMobile() {
    return window.innerWidth <= 640;
  }
  getIPAddress() {
    // this.http.get("http://api.ipify.org/?format=json").subscribe((res: any) => {
    //   this.ipAddress = res.ip;
    // });
  }
  ngOnInit() {
    this.IsAdmin = false;
    if (!this.IsSiteMaintenance) {
      if (!localStorage.getItem('Category') || localStorage.getItem('Category') == '') {

        this.fs.genericService("GET", "category", ["Status", "==", true]).subscribe((res: any) => {
          this.categoryArr = res;
          this.categoryArr.sort(this.dynamicSort("categoryOrder"));
          localStorage.setItem('Category', JSON.stringify(res));
        });

      }
      else {
        var cat = JSON.parse(localStorage.getItem('Category'));
        this.categoryArr = cat;
        this.categoryArr.sort(this.dynamicSort("categoryOrder"));
      }
      this.IsAdmin = false;
    }

    firebase.auth().onAuthStateChanged((user) => {


      this.bindModel = user;
      if (this.custService.userDetails && !this.IsSiteMaintenance) {

        // this.fs.genericService("GET", "cart", ["userId", "==", this.custService.userDetails.uid]).subscribe(res => {

        //   if (res !== undefined && res.length > 0)
        //     this.cartNumber = res.length;
        //   else
        //     this.cartNumber = '';



        // });

      }

    });
  }

  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }
  getCartNumber() {

  }
  search() {
    if (this.searchText !== '') {
      this.router.navigateByUrl("/Products?query=" + this.searchText);

    }
  }
  clearSearch() {
    this.searchText = "";
    this.router.navigateByUrl("/Products");

  }
  routeTo(url) {
    this.router.navigateByUrl(url);

  }
  navigateProductCategory(catCode) {
    this.searchText = '';
    // this.router.navigateByUrl('/Products?Category=' + catCode);
    var replaced = catCode.toLowerCase().replace(" ", "-");
    if (catCode.toLowerCase() == "soul care")
      replaced = "scented-candals";
    this.router.navigateByUrl('/collection/' + replaced)

  }

  signOut() {
    firebase.auth().signOut().then((result: any) => {
      localStorage.clear();
      this.router.navigateByUrl('');
    }).catch((error) => {
    });
  }
}
