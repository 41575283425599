// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  paymentKey: "Oc7jvOhi",
  firebaseConfig: {
    apiKey: "AIzaSyDDi0d3YfpEVdIRk1yZUOpxJjnzuV1XXsc",
  authDomain: "veganchemistry-c62c5.firebaseapp.com",
  projectId: "veganchemistry-c62c5",
  storageBucket: "veganchemistry-c62c5.appspot.com",
  messagingSenderId: "277989313422",
  appId: "1:277989313422:web:8e437f7ede56ad3538f2df",
  measurementId: "G-2GJSD71W9Y"

  },
  IsSiteMaintenance:false,
  WeFastAPIKey:'10E760FC5B53C524209F262AA96040097FC2AA71'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
