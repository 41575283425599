import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { FireBaseService } from 'src/app/services/fire-base.service';
import * as _loadashC from 'lodash';
import { MatSnackBar } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  
  faqDatasource = [];
  isMobile() {
    return window.innerWidth <= 640;
}
activeIndex=-1;
  constructor(private custService: CustomerService, private fs: FireBaseService) {
    this.custService.showLoader();

  }
  setActiveIndex(i){
    if(this.activeIndex==i){
      this.activeIndex=-1;
    }
    else
    this.activeIndex=i;
  }
  ngOnInit() {
    var docs=document.getElementById('footer');
    docs.style.position='';
    window.scrollTo(0,0);
    this.fs.genericService("GET", "faqs", ["Status", "==", true]).subscribe((res: any) => {
      if (res) {
        this.faqDatasource = [];
        res.forEach(element => {
          element.question = element.question.replace(/(<([^>]+)>)/g, "");
          element.answer = element.answer.replace(/(<([^>]+)>)/g, "")

        });
        this.faqDatasource = _loadashC.cloneDeep(res);
      }
      this.custService.hideLoader();


    });

  }

}
