import { Component, OnInit, Input } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { FireBaseService } from 'src/app/services/fire-base.service';
import { Router } from '@angular/router';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  categoryArr = [];
  IsAdmin = false;
  @Input('IsAdmin') set _IsAdmin(val) {
    this.IsAdmin = val;
  }
  constructor(private custService: CustomerService, private fs: FireBaseService, private router: Router) {

  }
  geolocationPosition: any;
  AdminMenuArr = [

    
   



  ];
  isMobile() {
    return window.innerWidth <= 640;
  }
  ngOnInit() {



    if (window.location.href.indexOf('Admin') > -1) {
      this.IsAdmin = true;
    }
    // else
    //   this.IsAdmin = false;
    // this.fs.genericService("GET", "category", ["Status", "==", true]).subscribe((res: any) => {
    //   this.categoryArr = res;
    //   this.categoryArr.sort(this.dynamicSort("categoryOrder"));

    // });

    
  }
  navigateProductCategory(catCode) {
    // this.router.navigateByUrl('/Products?Category=' + catCode)
    var replaced=catCode.toLowerCase().replace(" ","-");
    this.router.navigateByUrl('/collection/' + replaced)

  }
  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }
}
