import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BtnAddtocartComponent } from './elements/btn-addtocart/btn-addtocart.component';
import { MatBadgeModule } from '@angular/material/badge';
import { PreloaderComponent } from './preloader/preloader.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatOptionModule,
    MatDialogRef,
    MatAccordion
} from "@angular/material";
import { CdkCellDef, CdkColumnDef, CdkHeaderCellDef, CdkHeaderRowDef, CdkRowDef } from '@angular/cdk/table';
import { LoginComponent } from '../customer/login/login.component';
import { NewUserComponent } from '../customer/login/new-user/new-user.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { TermsandconditionsComponent } from './termsandconditions/termsandconditions.component';
import { PolicyComponent } from './policy/policy.component';
import { FaqComponent } from './faq/faq.component';
import { AvatarModule } from 'ngx-avatar';
import { HttpClientModule } from '@angular/common/http';
import { AboutUsComponent } from './aboutus/aboutus.component';
import { ContactUsComponent } from './contactus/contactus.component';

@NgModule({
    exports: [
        BtnAddtocartComponent,MatExpansionModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatStepperModule,
        MatOptionModule,
        PreloaderComponent,
        FaqComponent,AvatarModule
    ],
    declarations: [
        BtnAddtocartComponent,ContactUsComponent,
        LoginComponent,NewUserComponent,
        PreloaderComponent,TermsandconditionsComponent,PolicyComponent,FaqComponent,AboutUsComponent
    ],

    imports: [
        CommonModule, MatInputModule,MatPasswordStrengthModule,MatExpansionModule,HttpClientModule,AvatarModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,MatToolbarModule,
        MatSortModule,
        MatTableModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        FormsModule,
        NgbModule, MatStepperModule,
        ReactiveFormsModule, MatDialogModule, MatMenuModule
    ],

    providers: [
        CdkCellDef,
        CdkColumnDef,
        CdkHeaderCellDef,
        CdkHeaderRowDef,
        CdkRowDef,
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false }
          }
    ],
    entryComponents: [LoginComponent,NewUserComponent,TermsandconditionsComponent,PolicyComponent,AboutUsComponent]
})
export class SharedModule { }
