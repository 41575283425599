import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {

  constructor(private custService:CustomerService) {
    this.custService.showLoader();

   }
   isMobile() {
    return window.innerWidth <= 640;
}
  ngOnInit() {
    this.custService.hideLoader();
    var docs=document.getElementById('footer');
    docs.style.position='';
    window.scrollTo(0,0);
  }

}
