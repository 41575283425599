import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase';
import * as Rx from 'rxjs';
import { Injectable } from '@angular/core';
import { FireBaseService } from './fire-base.service';
import { eventNames } from 'process';
declare var fbq: any;

@Injectable({providedIn:'root'})
export class CustomerService {
    userDetails: any;
    cartNumber = '';
    cartDetails = [];
    selectedAddress = {};
    selectedAddressIndex = 0;
    listeners: any;

    eventsSubject: any;
    showLoaderFlag=true;
    events: any;
    On(name, listener) {
        if (!this.listeners[name]) {
            this.listeners[name] = [];
        }

        this.listeners[name].push(listener);
    }

    Broadcast(name, ...args) {
        this.eventsSubject.next({
            name,
            args
        });
    }

    showLoader(){
this.showLoaderFlag=true;
    }

    hideLoader(){
        this.showLoaderFlag=false;
    }
    setUserDetails(userDetailsFromLogin) {
        this.userDetails = userDetailsFromLogin;
    }
    setCartDetails(cart) {
        this.cartDetails = cart;
    }
    resetCartDetails() {
        this.cartDetails = [];
    }
    setSelectedAddress(address) {
        this.selectedAddress = address;
    }
    resetSelectedAddress() {
        this.selectedAddress = {};
    }

    setSelectedAddressIndex(index) {
        this.selectedAddressIndex = index;
    }
    resetSelectedAddressIndex() {
        this.selectedAddressIndex = 0;
    }
    constructor() {
        this.listeners = {};
        this.eventsSubject = new Rx.Subject();

        
    }
    logEventParams(eventName,valueJSON) {
        const analytics = firebase.analytics();
        
        // [START analytics_log_event_params]
        analytics.logEvent(eventName, valueJSON);
        if(eventName.toLowerCase().indexOf("visit")){
            fbq('track', "VIEW_CONTENT");
        }
        // fbq('trackCustom', eventName, valueJSON);

        // [END analytics_log_event_params]
      }
}