import { Component, OnInit, Inject } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { FireBaseService } from '../../services/fire-base.service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { MatSnackBar, MatDialogRef } from '@angular/material';
import { CustomerService } from 'src/app/services/customer.service';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-customer-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],

})
export class LoginComponent implements OnInit {
  dataSource = [];
  bindModel = {
    "Password": "",
    "UserName": ''
  }
  data: any;
  invalidMailId = false;
  constructor(private objMatSnackBar: MatSnackBar, private cust: CustomerService, private fs: FireBaseService, private title: Title, private router: Router) {
    this.cust.hideLoader();
  }

  isMobile() {
    return window.innerWidth <= 640;
  }
  loginGM() {
    this.bindModel.UserName='';
    this.bindModel.Password='';

    this.cust.showLoader();
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider).then((result) => {

      this.setUserDetails(result.user);
     


    }).catch((error) => {
    });
  }
  setUserDetails(user) {

    this.fs.genericService("GET", "users", ["userId", "==", user.uid]).subscribe(res => {
      if (res && res.length == 0) {
        var myObj = {}
        myObj["displayName"] = user["displayName"];
        myObj["email"] = user["email"];
        myObj["isAnonymous"] = user["isAnonymous"];
        myObj["emailVerified"] = user["emailVerified"];
        myObj["phoneNumber"] = user["phoneNumber"];
        myObj["photoURL"] = user["photoURL"];
        myObj["userId"] = user["uid"];
        myObj['lastLoggedInIP'] = "";
        myObj['availedCoupons'] = [];
        myObj['availableCoupons'] = [];
        this.fs.genericService("GET", "coupons", ["Status", "==", true]).subscribe((res: any) => {
          if (res && res.length > 0) {
            res.forEach(element => {
              myObj['availableCoupons'].push(element.couponCode);
            });
          }

          this.fs.genericService("POST", "users", null, myObj).subscribe(resp => {
            if (resp) {
              // this.bindModel = myObj;
            }
          });
        });
      }
    });
    // tslint:disable-next-line:curly
    if (!localStorage.getItem('token') || localStorage.getItem('token') == '')
      this.fs.genericService("GET", "userrole", ["usermailid", "==", user.email]).subscribe(res => {
        if (res !== undefined && res.length > 0) {
          localStorage.setItem('token', res[0]["role"]);
        }
        else {
          localStorage.removeItem('token');

        }
        var redirectPath = localStorage.getItem('RedirectToUrl');
        if (redirectPath && redirectPath != '') {
          localStorage.removeItem('RedirectToUrl');
          this.router.navigateByUrl(redirectPath);
        }
        else {

          
            this.router.navigateByUrl("");

        }

      });
  }

  loginFB() {
    this.cust.showLoader();
    var provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope('user_birthday');
    provider.setCustomParameters({
      'display': 'popup'
    });
    firebase.auth().signInWithPopup(provider).then((result: any) => {
      if (!this.data || (!this.data.IsFromCart && !this.data.IsFromPlaceOrder))
        this.router.navigateByUrl('/Products');
    }).catch(function (error) {
    });
  }
  close() {
  }
  ngOnInit() {
    if (this.isMobile()) {

      var docs = document.getElementById('footer');
      docs.style.position = 'fixed';

    }
  }
  resetPassword() {
    if (this.bindModel.UserName !== '') {
      firebase.auth().sendPasswordResetEmail(this.bindModel.UserName)
        .then((res) => {
          this.objMatSnackBar.open('Password Reset Instructions have been sent to your Email ID', '', { duration: 3000, });
        })
        .catch(error => {

        });
    }
    else {
      this.objMatSnackBar.open('Please enter a valid Email ID!', '', { duration: 3000, });

    }
  }
  login() {
    if (this.bindModel.UserName !== '' && this.bindModel.Password !== '') {

      firebase.auth().signInWithEmailAndPassword(this.bindModel.UserName, this.bindModel.Password)
        .then((result) => {
          this.close();

          this.setUserDetails(result.user);


        })
        .catch(error => {

          if (error["code"] === "auth/user-not-found") {
            this.invalidMailId = true;
          }
        });
    }
    else
      this.objMatSnackBar.open('Please enter a valid Email ID & Password!', '', { duration: 3000, });

  }
  redirectToSignUp() {
    this.router.navigateByUrl('/SignUp')
  }

}