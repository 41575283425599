import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { SEOService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor(private seo:SEOService,private custService:CustomerService) {
    this.custService.showLoader();
    this.seo.updateTitle("A little About Vegan Chemistry");
    this.seo.updateDescription("From Rolling Papers to Pre Rolled Cones, buy all the smoking accessories under one roof, Vegan Chemistry. We are just a click away! Try Out!");

   }
   isMobile() {
    return window.innerWidth <= 640;
}
  ngOnInit() {
    this.custService.hideLoader();
    var docs=document.getElementById('footer');
    docs.style.position='';
    window.scrollTo(0,0);
  }

}
