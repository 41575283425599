import { Component, OnInit, Input } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { FireBaseService } from 'src/app/services/fire-base.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { NewUserComponent } from 'src/app/customer/login/new-user/new-user.component';
import { Router } from '@angular/router';
declare var fbq:any;

@Component({
  selector: 'addtocartbtn',
  templateUrl: './btn-addtocart.component.html',
  styleUrls: ['./btn-addtocart.component.scss']
})
export class BtnAddtocartComponent implements OnInit {
  product: any;
  @Input('product') set _product(val) {
    this.product = val;
  }
  disabled = false;
  @Input('disabled') set _disabled(val) {
    this.disabled = true;
  }

  constructor(private router: Router, private dialog: MatDialog, private cusService: CustomerService, private fs: FireBaseService, private objMatSnackBar: MatSnackBar) { }

  ngOnInit() {
  }
  checkIfDisabled() {
    if (this.product && this.product.Inventory <= 0)
      return true;
    else
      return false;
  }
  AddToCart() {

    if (this.cusService.userDetails && this.product) {

      if (this.product.Inventory <= 0) {
        this.objMatSnackBar.open('This product is out of stock', '', { duration: 2000, });
        return false;

      }
      this.objMatSnackBar.open('Adding this product to cart ', '', { duration: 2000, });

      var cartObj = {

       
        ...this.product,
        qty: 1,
        addedDate: new Date().toJSON()

      }
      delete cartObj['key'];

      delete cartObj['ProductImage'];
      delete cartObj["userId"];
      delete cartObj["emailId"];

      cartObj["userId"]= this.cusService.userDetails.uid;
      cartObj["emailId"]=this.cusService.userDetails.email;
      
      cartObj['ProductImage'] = [];
      if (this.product["ProductImage"] && this.product["ProductImage"].length > 0)
        cartObj['ProductImage'] = [this.product["ProductImage"][0]];
      this.fs.genericService("post", "cart", null, cartObj).subscribe(res => {
      
      
        if (res !== null && res !== undefined) {
          this.cusService.logEventParams('add_product_to_cart',cartObj);
          var fbqPar={
            id:this.product["ProductUniqueCode"],
            quantity: 1,
            ...this.product
          }
          fbq('track', 'AddToCart',{content_name:this.product["ProductUniqueCode"],contents:fbqPar,currency:'INR',value:this.product.ProductPrice});

          this.objMatSnackBar.open('Product has been added to Cart successfully', '', { duration: 2000, });

        }
      }, error => {
        this.objMatSnackBar.open('Error While adding Product to cart', '', { duration: 2000, });

      });
    }
    else {
      //show login
      localStorage.setItem('RedirectToUrl', window.location.pathname);
      this.router.navigateByUrl('/Login')
    }

  }
}
