import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-accessDenied',
  templateUrl: './accessdenied.component.html',
})
export class AccessDeniedComponent implements OnInit {
 
  constructor(private custService: CustomerService) {
    this.custService.showLoader();

  }
  
  ngOnInit() {
    
      this.custService.hideLoader();
  }
}
