import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { SEOService } from 'src/app/services/seo.service';
import { MatSnackBar } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _loadashC from 'lodash';
declare var fbq:any;

@Component({
  selector: 'app-contactus-us',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactUsComponent implements OnInit {
  bindModel = {
    EmailId: '',
    PhoneNumber: '',
    Name: '',
    MailBody: ''

  }
  resetModel = {};
  constructor(private http: HttpClient,private objMatSnackBar: MatSnackBar,private seo:SEOService,private custService:CustomerService) {
    this.custService.showLoader();
    this.seo.updateTitle("Get In Touch With Vegan Chemistry");
    this.seo.updateDescription("From Rolling Papers to Pre Rolled Cones, buy all the smoking accessories under one roof, Vegan Chemistry. We are just a click away! Try Out!");

   }
   isMobile() {
    return window.innerWidth <= 640;
}
  ngOnInit() {
    this.custService.hideLoader();
    var docs=document.getElementById('footer');
    docs.style.position='';
    window.scrollTo(0,0);
  }

  validateDetails() {
    var resp = true;
    if (this.bindModel.EmailId.trim() === "" || this.bindModel.MailBody.trim() == "" || this.bindModel.Name.trim() === "" || this.bindModel.PhoneNumber.trim() === "")
      resp = false;
    return resp;
  }
  SendMail() {
    if (this.validateDetails()) {
      var endpoint = 'https://us-central1-veganchemistry-c62c5.cloudfunctions.net/httpEmail';
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      httpOptions.headers.append('Access-Control-Allow-Origin', 'http://localhost:8080');
      httpOptions.headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
      var objSent=_loadashC.cloneDeep(this.bindModel);
      objSent.MailBody+="<br> <b> This query was raised from "+objSent.EmailId+". Name:"+objSent.Name+" ("+objSent.PhoneNumber+") </b>";
      this.http.post(endpoint, objSent, httpOptions).subscribe(res => {

      });
      this.bindModel = _loadashC.cloneDeep(this.resetModel);
      this.objMatSnackBar.open('Mail Sent Successfully', '', { duration: 2000, });
      fbq('track', 'Contact');
      
    }
    else {
      this.objMatSnackBar.open('Enter Mandatory Details', '', { duration: 2000, });

    }
  }

}
