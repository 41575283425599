import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PolicyComponent } from './shared/policy/policy.component';
import { FaqComponent } from './shared/faq/faq.component';
import { TermsandconditionsComponent } from './shared/termsandconditions/termsandconditions.component';
import { AboutUsComponent } from './shared/aboutus/aboutus.component';
import { AccessDeniedComponent } from './shared/accessdenied/accessdenied.component';
import { ContactUsComponent } from './shared/contactus/contactus.component';
import { LoginComponent } from './customer/login/login.component';
import { NewUserComponent } from './customer/login/new-user/new-user.component';

const routes: Routes = [
  { path: 'Policy', component:PolicyComponent},
  { path: 'TC', component:TermsandconditionsComponent},
  { path: 'who-we-are', component:AboutUsComponent},
  { path: 'contact-us', component:ContactUsComponent},
  { path: 'Login', component:LoginComponent},
  { path: 'SignUp', component:NewUserComponent},

  
  
  
  { path: 'Faq', component:FaqComponent},

  
    { path: '', loadChildren: './customer/customer-module#CustomerModule'},
    { path: '**', redirectTo:''},


];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }